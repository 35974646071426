import React from 'react';
import Container from '../components/Container';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const liClass = 'list-group-item px-0 py-2 border-0 lh-sm';

function Item({ icon, text }) {
    return (
        <li className={liClass}>
            <div className='text-decoration-none text-body color-black d-flex flex-row align-content-center'>
                <FontAwesomeIcon
                    className='text-gray me-3 fa-fw mt-1'
                    icon={icon}
                />
                <span dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </li>
    );
}

function ItemLink({ icon, text, link }) {
    return (
        <li className={liClass}>
            <a
                href={link}
                className='text-decoration-none text-body color-black'
            >
                <FontAwesomeIcon className='text-gray me-3 fa-fw' icon={icon} />
                {text}
            </a>
        </li>
    );
}

function Contact({ pData }) {
    return (
        <div className='bg-white rounded shadow-sm p-3 d-flex align-items-start h-100'>
            <img
                src='https://secure.gravatar.com/avatar/4eb08bcdbe572222dfe9268e379f1c49?s=256&d=mm&r=g'
                alt=''
                width='120'
                height='120'
                className='img-fluid rounded-circle img-thumbnail shadow-sm'
            />
            <div className='ms-4'>
                <h5 className='mb-0 '>{pData.title}</h5>
                <span className='small text-uppercase text-muted'>
                    {pData.acf_people.title}
                </span>
                <ul className='social mb-0 list-group mt-1'>
                    {pData.acf_people.email && (
                        <ItemLink
                            link={'mailto:' + pData.acf_people.email}
                            text={pData.acf_people.email}
                            icon='envelope'
                        />
                    )}
                    {pData.acf_people.phone && (
                        <Item text={pData.acf_people.phone} icon='envelope' />
                    )}
                    {pData.acf_people.languages && (
                        <Item
                            text={pData.acf_people.languages}
                            icon='language'
                        />
                    )}
                    {pData.acf_people.regionalLocation && (
                        <ItemLink
                            link={pData.acf_people.regionalLocation?.uri}
                            text={pData.acf_people.regionalLocation?.title}
                            icon='map-marker-alt'
                        />
                    )}
                    {pData.acf_people.linkedAddress === 'custom' && (
                        <Item
                            text={pData.acf_people.address}
                            icon='map-marker-alt'
                        />
                    )}
                </ul>
            </div>
        </div>
    );
}

export default function ContactPerson({ data }) {
    return (
        <Container>
            <h1>Contact Persons</h1>
            <div className='row'>
                {data.allWpPeople.nodes.map((person) => {
                    return (
                        <div className='col-lg-6 mb-4'>
                            <Contact pData={person} />
                        </div>
                    );
                })}
            </div>
        </Container>
    );
}

export const query = graphql`
    {
        allWpPeople(sort: { order: ASC, fields: date }) {
            nodes {
                title
                acf_people {
                    title
                    regionalLocation {
                        ... on WpLocation {
                            id
                            title
                            uri
                        }
                    }
                    phone
                    linkedAddress
                    languages
                    email
                    address
                }
            }
        }
    }
`;
